<template>
  <div v-if="client && clientLoaded">
    <div
      v-if="editMode"
      class="w-6/12 mx-auto flex flex-col mobile:w-full mobile:px-2 mobile:mx-0"
    >
      <h2 class="mb-4">Muuda klienti</h2>
      <h4 class="mb-2">Nimi</h4>
      <input
        type="text"
        v-model="editables.name"
        :class="
          $v.$error && $v.editables.name.$invalid ? 'shadow-dangeroutline' : ''
        "
        class="bg-offwhite w-full flex-grow shadow focus:shadow-outline mobile:mb-3 mb-4"
      />
      <div class="flex flex-col mb-4" v-if="editMode">
        <div class="flex flex-row items-center mb-2">
          <div
            class="flex rounded-full p-2 border-green border-2 items-center justify-center mr-2"
          >
            <img src="/bc21/placeholder.svg" class="filter-to-green h-4 w-4" />
          </div>
          <location-inaddress
            v-if="editMode"
            :text="'Sisestage kliendi aadress'"
            :location="client.address ? client.address.long_address : ''"
            class="w-full"
            @locationChanged="handleClientLocationChange"
            @clearLocation="editables.address = null"
          >
          </location-inaddress>
        </div>
        <div class="flex flex-row items-center mb-2">
          <div
            class="flex rounded-full p-2 border-green border-2 items-center justify-center mr-2"
          >
            <img src="/bc21/mail.svg" class="filter-to-green h-4 w-4" />
          </div>
          <input
            v-if="editMode"
            type="text"
            v-model="emailField"
            :class="
              $v.$error && $v.editables.email.$invalid
                ? 'shadow-dangeroutline'
                : ''
            "
            class="bg-offwhite flex-grow shadow focus:shadow-outline mobile:mb-3"
          />
        </div>
        <div class="flex flex-row items-center mb-4">
          <div
            class="flex rounded-full p-2 border-green border-2 items-center justify-center mr-2"
          >
            <span>
              <img src="/bc21/phone.svg" class="filter-to-green h-4 w-4"
            /></span>
          </div>
          <select
            v-if="editMode"
            v-model="countryCode"
            class="mobile:w-3/12 medium:w-3/12 w-2/12 mr-2"
          >
            <option value="+372"><img src="" />+372</option>
          </select>
          <input
            v-if="editMode"
            type="tel"
            pattern="[0-9\s]*"
            v-model="phoneNumberInput"
            :class="
              $v.$error &&
              phoneNumberInput &&
              phoneNumberInput.length > 0 &&
              $v.phoneNumberInput.$invalid
                ? 'shadow-dangeroutline'
                : ''
            "
            class="bg-offwhite flex-grow shadow focus:shadow-outline"
          />
        </div>
        <div v-if="client.is_company" class="flex flex-col justify-start mb-4">
          <h4 class="mb-2">Registrikood</h4>
          <div class="flex flex-row mb-4 items-center">
            <div
              class="flex rounded-full border-green border-2 items-center justify-center p-1 mr-2"
            >
              <span
                class="typcn typcn-clipboard text-green text-2xl leading-none"
              ></span>
            </div>
            <input
              v-if="editMode"
              type="text"
              v-model="editables.register_code"
              placeholder="Ettevõtte registrikood"
              class="bg-offwhite flex-grow shadow focus:shadow-outline mobile:mb-3"
            />
          </div>
          <h4 class="mb-2">KMRK kood</h4>
          <div class="flex flex-row items-center">
            <div
              class="flex rounded-full border-green border-2 items-center justify-center p-1 mr-2"
            >
              <span
                class="typcn typcn-clipboard text-green text-2xl leading-none"
              ></span>
            </div>
            <input
              v-if="editMode"
              type="text"
              v-model="editables.tax_code"
              placeholder="Ettevõtte KMKR kood"
              class="bg-offwhite flex-grow mobile:mr-0 shadow focus:shadow-outline mobile:mb-3"
            />
          </div>
        </div>
      </div>
      <h4 class="mb-2">Kontaktisikud</h4>
      <client-contact-card
        v-for="contact in clientContacts"
        :key="contact.id"
        :contact="contact"
        :edit="true"
        @deleteContact="deleteClientContact"
        class="mb-2 flex-col flex"
      >
      </client-contact-card>
      <div class="flex">
        <button
          v-if="editMode && !contactAddActive"
          class="new-button-green"
          @click="contactAddActive = !contactAddActive"
        >
          <span class="typcn-plus typcn icon"></span>
          <span class="label">Lisa kliendile kontaktisik</span>
        </button>
      </div>

      <company-client-contact
        v-if="contactAddActive"
        :full-width="true"
        @close="contactAddActive = false"
        @save="saveClientContact"
      >
      </company-client-contact>
      <div class="flex mt-6 items-center w-full justify-center">
        <button class="new-button-green mr-2" @click="saveClient">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="cancelEditModalActive = true">
          <span class="icon typcn typcn-times"> </span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
    <div v-if="!editMode" class="flex flex-col bg-white rounded-md mobile:px-0">
      <div class="flex flex-col w-full">
        <div class="w-full flex flex-row justify-between mb-4" v-if="!editMode">
          <div class="flex justify-between">
            <router-link to="/clients" class="mr-3 mobile:mr-0">
              <button class="new-button-danger" @click="closeDetailView">
                <span class="typcn-chevron-left typcn icon"></span>
                <span class="label">Tagasi</span>
              </button>
            </router-link>
            <button
              class="new-button-pending mobile:hidden"
              @click="editMode = true"
            >
              <span class="typcn-edit typcn icon"></span>
              <span class="label">Muuda</span>
            </button>
          </div>

          <button
            class="new-button-danger mobile:hidden"
            @click="isConfirmDeleteModalActive = true"
          >
            <span class="icon"
              ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
            /></span>
            <span class="label">Kustuta</span>
          </button>
          <div class="hidden mobile:flex relative">
            <button
              @click="showMobileOptions = !showMobileOptions"
              class="new-button-green"
            >
              <span class="label">Valikud</span>
              <span
                class="typcn typcn-chevron-right icon transform"
                :class="showMobileOptions ? '-rotate-90' : 'rotate-90'"
              ></span>
            </button>
            <div
              class="mobile-options"
              :class="showMobileOptions ? 'open' : 'closed'"
            >
              <transition name="fade" :duration="{ enter: 250, leave: 50 }">
                <div class=" flex flex-col" v-if="showMobileOptions">
                  <button
                    class="new-button-pending mb-4"
                    @click="editMode = true"
                  >
                    <span class="typcn-edit typcn icon"></span>
                    <span class="label">Muuda</span>
                  </button>
                  <button
                    class="new-button-danger"
                    @click="isConfirmDeleteModalActive = true"
                  >
                    <span class="icon"
                      ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
                    /></span>
                    <span class="label">Kustuta</span>
                  </button>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex w-full mb-2">
          <div class="w-1/2 flex flex-col">
            <div class="flex">
              <h2 class="mr-4">
                {{ client.name }}
              </h2>
            </div>
            <span class="mr-4 tracking-wide">{{
              client.is_company ? "Äriklient" : "Eraklient"
            }}</span>
          </div>
        </div>
        <div
          class="flex flex-row w-full mobile:flex-col mobile:flex-wrap mobile:mb-6"
        >
          <div class="flex flex-row w-full mobile:flex-col">
            <div class="flex flex-col mobile:w-full mobile:mb-6 w-1/3">
              <h4 class="mb-2">Kontaktinfo</h4>
              <div>
                <div
                  v-if="$v.$error && $v.$invalid && editMode"
                  class="error-box"
                >
                  <div class="flex flex-col w-10/12 justify-center">
                    <span class="flex" v-if="!$v.editables.email.email"
                      >Meiliaadress on ebakorrektne</span
                    >
                    <span class="flex" v-if="!$v.editables.name.required"
                      >Palun sisestage kliendi nimi</span
                    >
                    <span
                      class="flex"
                      v-if="
                        phoneNumberInput.length > 0 &&
                          (!$v.phoneNumberInput.minLength ||
                            !$v.phoneNumberInput.maxLength)
                      "
                      >Telefoninumber on ebakorrektne (7-8 numbrit)</span
                    >
                  </div>
                </div>
              </div>
              <div class="flex flex-col mb-4" v-if="!editMode">
                <client-info-card :client="client"></client-info-card>
              </div>
            </div>
            <div
              class="flex flex-col mobile:w-full pr-4 mobile:pr-0 mobile:mb-6 w-1/3"
            >
              <h4 class="mb-2">Kliendi kontaktid</h4>
              <div v-if="client.contacts.length === 0">
                <span v-if="!editMode">Kliendil puuduvad kontakisikud</span>
              </div>
              <client-contact-card
                v-for="contact in clientContacts"
                :key="contact.id"
                :contact="contact"
                class="mb-2 flex-col flex"
              >
              </client-contact-card>
            </div>
            <div
              class="flex w-1/3 justify-start mobile:w-full pl-4 mobile:pl-0"
            >
              <div
                v-if="client.profile !== null"
                class="flex flex-col justify-start items-start mobile:items-start w-full"
              >
                <div class="flex items-center w-full mb-2">
                  <h4 class="mr-2">Kasutaja ühendatud</h4>
                  <span class="w-5 h-5 rounded-full bg-green"></span>
                </div>
                <div
                  class="flex items-center w-full bg-offwhite rounded-xl shadow"
                >
                  <div class="items-center justify-start flex w-auto">
                    <div class="p-2">
                      <span
                        v-if="
                          !Object.keys(this.client.profile.image).length > 0
                        "
                        class="typcn typcn-user border-green border-2 rounded-full text-green flex h-12 w-12 items-center justify-center text-4xl leading-none"
                      ></span>
                      <img
                        v-else
                        :src="profileImage"
                        class="h-12 w-12 rounded-full"
                      />
                    </div>
                  </div>
                  <span class="w-auto pl-2">
                    {{ client.profile.display_username }}</span
                  >
                </div>
              </div>
              <div
                v-if="client.profile === null"
                class="flex flex-col items-center mobile:items-start w-full"
              >
                <div v-if="client.join_request_sent" class="flex flex-col">
                  <span class="mb-2"
                    >Gewodo kasutaja loomise kutse saadetud meiliaadressile
                    <span class="font-medium">{{
                      `${client.join_request_email}`
                    }}</span
                    >.</span
                  >
                  <span v-if="joinRequestResent" class="mb-2">
                    Liitumise kutse edukalt uuesti saadetud!
                  </span>
                  <div class="flex flex-row">
                    <button
                      class="alt-button-pending mr-2"
                      @click="reSendJoinRequest"
                    >
                      <span class="icon typcn typcn-plus"></span>
                      <span class="label">Saada uuesti</span>
                    </button>
                    <button
                      class="alt-button-danger ml-2"
                      @click="deleteJoinRequest"
                    >
                      <span class="icon typcn typcn-trash"></span>
                      <span class="label">Tühista liitumise kutse</span>
                    </button>
                  </div>
                </div>

                <div
                  v-if="!client.join_request_sent"
                  class="flex flex-col w-full"
                >
                  <div class="flex items-center mb-2">
                    <h4 class="mr-3">Kasutaja ühendamata</h4>
                    <span class="w-4 h-4 bg-attention rounded-full"></span>
                  </div>

                  <span class="mb-2"
                    >Saada kasutajale Gewodo konto ühendamise taotlus</span
                  >
                  <div class="flex flex-col items-start w-full">
                    <input
                      type="text"
                      class="add-project-input mb-4 w-full"
                      placeholder="Kasutaja meiliaadress"
                      v-model="joinEmail"
                    />
                    <button class="new-button-green" @click="sendJoinRequest">
                      <span class="icon">
                        <img
                          src="/bc21/mail.svg"
                          class="h-4 w-4 filter-to-white"
                      /></span>
                      <span class="label">Saada</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 w-full rounded-gwdhalf" v-if="!editMode">
        <div class="bg-white rounded-b-md">
          <client-projects
            v-if="subPage === 1"
            :client="client"
          ></client-projects>
        </div>
      </div>
    </div>
    <confirm-modal
      text="Olete kindel, et soovite muudatused tühistada?"
      v-if="cancelEditModalActive"
      @closeModal="cancelEditModalActive = false"
      @confirm="cancelEdit()"
    ></confirm-modal>
    <delete-confirm-modal
      text="Olete kindel, et soovite valitud kliendi kustutada?"
      v-if="isConfirmDeleteModalActive"
      @closeModal="isConfirmDeleteModalActive = false"
      @confirmDelete="deleteClient()"
    >
    </delete-confirm-modal>
  </div>
</template>

<script>
import ClientProjects from "@/components/company/clients/ClientProjects";
import RequestHandler from "@/assets/mixins/RequestHandler";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal";
import {
  email,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import CompanyClientContact from "@/components/project/CompanyClientContact";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import ClientContactCard from "./ClientContactCard";
import ClientInfoCard from "@/components/reusable/ClientInfoCard";
export default {
  name: "ActiveClient",
  components: {
    LocationInaddress,
    CompanyClientContact,
    DeleteConfirmModal,
    ClientProjects,
    ClientContactCard,
    ConfirmModal,
    ClientInfoCard
  },
  mixins: [RequestHandler],

  data() {
    return {
      client: null,
      editMode: false,
      subPage: 1,
      joinEmail: "",
      isConfirmDeleteModalActive: false,
      phoneNumberInput: "",
      companyPhoneNumberInput: "",
      emailField: "",
      companyEmailField: "",
      countryCode: "+372",
      clientLoaded: false,
      editables: {
        name: "",
        address: "",
        email: "",
        telephone_number: "",
        register_code: "",
        tax_code: ""
      },
      companyAddActive: false,
      contactAddActive: false,
      showMobileOptions: false,
      clientContacts: [],
      cancelEditModalActive: false,
      joinRequestResent: false
    };
  },
  methods: {
    saveClientContact(contact) {
      this.clientContacts.push(contact);
    },
    deleteClientContact(contact) {
      this.clientContacts.splice(
        this.clientContacts.indexOf(
          this.clientContacts.find(item => item.id === contact.id)
        ),
        1
      );
    },
    handleClientLocationChange(e) {
      this.editables.address = e;
    },
    handleClientCompanyLocationChange(e) {
      this.editables.company.address = e;
    },
    reSendJoinRequest() {
      this.joinRequestResent = false;
      this.sendJoinRequest();
      this.joinRequestResent = true;
    },
    sendJoinRequest() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/clients/" +
          this.$route.params.clientId +
          "/request/join/",
        "post",
        true,
        { email: this.addEmailLowercased }
      ).then(res => {
        if (res) {
          this.retrieveClientData();
        }
      });
    },
    deleteJoinRequest() {
      this.$store.state.loading = true;
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/clients/" +
          this.client.id +
          "/request/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 201) {
          this.retrieveClientData();
        } else if (res.status === 200) {
          this.retrieveClientData();
        }
      });
      this.$store.state.loading = false;
    },
    retrieveClientData() {
      this.clientLoaded = false;
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/clients/" +
          this.$route.params.clientId +
          "/",
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          this.client = res.data;
          this.editables.name = this.client.name;
          this.editables.register_code = this.client.register_code;
          this.editables.tax_code = this.client.tax_code;
          document.title = `${this.client.name} | Gewodo`;
          if (this.client.address) {
            this.editables.address = this.client.address;
          } else {
            this.editables.address = null;
          }
          if (this.client.email) {
            this.emailField = this.client.email;
          } else {
            this.emailField = "";
          }
          this.clientContacts = this.client.contacts;
          if (
            this.client.telephone_number &&
            this.client.telephone_number.length > 0
          ) {
            this.phoneNumberInput = this.client.telephone_number.substring(4);
          } else {
            this.phoneNumberInput = "";
          }
          this.clientLoaded = true;
        }
      });
    },
    deleteClient() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/clients/" +
          this.client.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          this.$router.push("/clients");
        }
      });
    },
    saveClientContacts() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/client/" +
          this.client.id +
          "/contacts/edit/",
        "patch",
        true,
        this.clientContacts
      ).then(res => {
        if (res.status === 200) {
          // TODO handle success
          res;
        }
      });
    },
    saveClient() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.phoneNumberInput.length > 0) {
          this.editables.telephone_number =
            this.countryCode + this.phoneNumberInput;
        }
        this.saveClientContacts();
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/clients/" +
            this.client.id +
            "/edit/",
          "patch",
          true,
          this.editables
        ).then(res => {
          if (res.status === 200) {
            this.retrieveClientData();
            this.editMode = false;
          }
        });
      }
    },
    closeDetailView() {
      this.$emit("close");
    },
    cancelEdit() {
      this.cancelEditModalActive = false;
      this.editMode = false;
    }
  },
  computed: {
    formattedJoinRequestEmail() {
      if (this.client.join_request_email) {
        return "(" + this.client.join_request_email + ")";
      }
      return "";
    },
    profileImage() {
      if (this.client.profile !== null) {
        if (
          Object.keys(this.client.profile.image).length === 0 ||
          Object.keys(this.client.profile.image.image).length === 0
        ) {
          return "/favicon.ico";
        } else {
          return (
            process.env.VUE_APP_MEDIA_URL + this.client.profile.image.image
          );
        }
      }
      return "/favicon.ico";
    },
    emailLowercased() {
      return this.emailField.toLowerCase();
    },
    companyEmailLowercased() {
      return this.companyEmailField.toLowerCase();
    },
    addEmailLowercased() {
      return this.joinEmail.toLowerCase();
    }
  },
  watch: {
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.phoneNumberInput = this.phoneNumberInput.replace(test, "");
      }
    },
    emailField() {
      this.editables.email = this.emailLowercased;
    }
  },
  mounted() {
    this.retrieveClientData();
    this.clientLoaded = true;
  },
  validations() {
    if (this.phoneNumberInput !== null && this.phoneNumberInput.length > 0) {
      {
        return {
          editables: {
            name: {
              required
            },
            email: {
              email
            }
          },
          phoneNumberInput: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(8)
          }
        };
      }
    } else {
      return {
        editables: {
          name: {
            required
          },
          email: {
            email
          }
        }
      };
    }
  }
};
</script>

<style lang="scss">
.client-tray-item {
  @apply flex justify-center rounded-full items-center cursor-pointer duration-100 w-4/12 py-2;
  .client-tray-icon {
    @apply text-4xl mr-1 leading-none;
  }
  .client-tray-label {
    @apply text-xl font-semibold ml-1;
  }
}
.mobile-options {
  @apply bg-offwhite rounded-md;
  position: absolute;
  top: 115%;
  right: 0%;
  transition: max-height 0.15s linear, padding 0.15s linear;
  &.open {
    @apply py-5 px-3;
    max-height: 64rem;
  }
  &.closed {
    max-height: 0rem;
  }
}
</style>
