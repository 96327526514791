<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center mb-2">
      <div class="flex">
        <div
          class="flex rounded-full p-2 border-green border-2 items-center justify-center h-9 w-9"
        >
          <img src="/bc21/placeholder.svg" class="filter-to-green h-4 w-4" />
        </div>
      </div>
      <div class="flex mobile:text-xs pl-2">
        <span> {{ client.address ? client.address.long_address : "-" }}</span>
      </div>
    </div>
    <div class="flex flex-row items-center mb-2">
      <div
        class="flex rounded-full p-2 border-green border-2 items-center justify-center mr-2"
      >
        <img src="/bc21/mail.svg" class="filter-to-green h-4 w-4" />
      </div>
      <a
        :href="client.email"
        class="hover:text-green duration-75"
        v-if="client.email"
      >
        {{ client.email ? client.email : "-" }}</a
      >
      <span v-else>-</span>
    </div>
    <div class="flex flex-row items-center mb-4">
      <div
        class="flex rounded-full p-2 border-green border-2 items-center justify-center mr-2"
      >
        <span>
          <img src="/bc21/phone.svg" class="filter-to-green h-4 w-4"
        /></span>
      </div>
      <a
        :href="'tel:' + client.telephone_number"
        class="hover:text-green duration-75"
        v-if="client.telephone_number"
      >
        {{ client.telephone_number ? client.telephone_number : "-" }}
      </a>
      <span v-else>-</span>
    </div>
    <div v-if="client.is_company" class="flex flex-col justify-start mb-4">
      <div class="flex flex-col mb-2 items-start">
        <div class="flex items-center mb-1">
          <div
            class="flex rounded-full border-green border-2 items-center justify-center p-1 mr-2"
          >
            <span
              class="typcn typcn-clipboard text-green text-2xl leading-none"
            ></span>
          </div>
          <h4>Registrikood</h4>
        </div>

        <span> {{ client.register_code ? client.register_code : "-" }}</span>
      </div>
      <div class="flex flex-col items-start">
        <div class="flex items-center mb-1">
          <div
            class="flex rounded-full border-green border-2 items-center justify-center p-1 mr-2"
          >
            <span
              class="typcn typcn-clipboard text-green text-2xl leading-none"
            ></span>
          </div>
          <h4>KMRK kood</h4>
        </div>

        <span>{{ client.tax_code ? client.tax_code : "-" }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
