<template>
  <div class="">
    <div class="flex flex-row items-center mb-6">
      <span class="mr-4 text-2xl font-semibold">Projektid</span>
      <div class="ml-2">
        <button
          class="alt-button-green"
          @click="$router.push('/projects/new/?client=' + client.id)"
        >
          <span class="typcn-plus typcn icon"></span>
          <span class="label">Lisa projekt</span>
        </button>
      </div>
    </div>
    <div v-if="projectsLoaded" class="my-2">
      <router-link
        :to="'/projects/' + project.uuid"
        v-for="project in projects"
        :key="project.uuid"
        class="flex w-full flex-col"
      >
        <draft-description :data="project"></draft-description>
      </router-link>
    </div>
    <div v-if="projectsLoaded && projects.length === 0" class="my-2">
      <span>Kliendiga puuduvad seotud projektid</span>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import StatusParser from "@/assets/mixins/StatusParser";
import DraftDescription from "@/components/reusable/DraftDescription";

export default {
  name: "ClientProjects",
  props: {
    client: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { DraftDescription },
  mixins: [RequestHandler, StatusParser],
  data() {
    return {
      projectsLoaded: false,
      projects: []
    };
  },
  methods: {
    loadClientProjects() {
      this.apiRequest(
        "notes/drafts/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/client/" +
          this.client.id +
          "/",
        "get",
        true
      ).then(res => {
        this.projects = res.data;
        this.projectsLoaded = true;
      });
    },
    offerStatus(project) {
      return this.parseProjectStatus(project.status);
    }
  },
  mounted() {
    this.loadClientProjects();
  }
};
</script>
