<template>
  <div
    class="flex flex-col bg-offwhite rounded-xl cursor-pointer shadow"
    @click="expanded = !expanded"
  >
    <div
      class="flex items-center p-2 "
      :class="
        expanded
          ? 'bg-offerwhite rounded-t-xl'
          : 'hover:bg-offerwhite rounded-xl'
      "
    >
      <div class="flex w-auto mr-4">
        <div class="bg-darkgrey p-2 rounded-full">
          <img src="/bc21/kliendid.svg" class="h-5 w-5 filter-to-white" />
        </div>
      </div>
      <div class="w-auto">
        <h4>{{ contact.name }}</h4>
      </div>
      <div class="w-auto ml-auto">{{ contact.role }}</div>
      <div class="w-auto ml-3 flex">
        <span
          class="typcn-chevron-right typcn transform duration-100"
          :class="expanded ? '-rotate-90' : 'rotate-90'"
        ></span>
      </div>
    </div>
    <div class="flex flex-col mt-3 px-2 pb-2" v-if="expanded">
      <h4 class="mb-3">Kontaktandmed</h4>
      <div class="flex mb-2 items-center">
        <div
          class="flex rounded-full p-2 border-green border-2 items-center justify-center mr-2"
        >
          <img src="/bc21/phone.svg" class="filter-to-green h-4 w-4" />
        </div>
        <div class="w-auto">
          <a
            class="hover:text-green cursor-pointer"
            :href="'tel:' + contact.telephone_number"
            >{{ contact.telephone_number }}</a
          >
        </div>
      </div>
      <div class="flex items-center mb-2">
        <div
          class="flex rounded-full p-2 border-green border-2 items-center justify-center mr-2"
        >
          <img src="/bc21/mail.svg" class="filter-to-green h-4 w-4" />
        </div>
        <div class="w-auto">
          <a
            :href="'mailto:' + contact.email"
            class="hover:text-green cursor-pointer"
            >{{ contact.email }}</a
          >
        </div>
      </div>
      <button
        class="new-button-danger"
        @click.stop="deleteContactConfirmation = true"
        v-if="edit"
      >
        <span class="icon">
          <img class="/bc21/trash.svg" />
        </span>
        <span class="label">Kustuta</span>
      </button>
    </div>
    <confirm-modal
      text="Olete kindel, et soovite kliendi kontakti kustutada?"
      v-if="deleteContactConfirmation"
      @closeModal="deleteContactConfirmation = false"
      @confirm="deleteContact"
    ></confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
export default {
  components: { ConfirmModal },
  props: {
    contact: {
      type: Object,
      default: () => {
        return {};
      }
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: false,
      deleteContactConfirmation: false
    };
  },
  methods: {
    deleteContact() {
      this.$emit("deleteContact", this.contact);
    }
  }
};
</script>
